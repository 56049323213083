@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Roboto:wght@400;500;700&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
	font-family: 'Roboto', sans-serif;
	background-color: #f5f5f5;
}

textarea:focus,
input:focus {
	outline: none;
}

.anchor{
	padding-top: 68px;
	margin-top: -68px;

	&-detail{
		padding-top: 136px;
		margin-top: -136px;
	}
}

input[type='checkbox'] {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
	}

	// Box.
	& + label:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 18px;
		height: 18px;
		background: transparent;
		border: 1px solid #333;
		border-radius: 1px;
	}

	// Box hover
	&:hover + label:before {
		background: transparent;
	}

	// Box checked
	&:checked + label:before {
		background: transparent;
	}

	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}

	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}

	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		content: '';
		position: absolute;
		left: 4px;
		top: 9px;
		background: #333;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 #333, 4px 0 0 #333, 4px -2px 0 #333, 4px -4px 0 #333,
			4px -6px 0 #333, 4px -8px 0 #333;
		transform: rotate(45deg);
	}
}

h1 {
	font-size: 24px;
	line-height: 34px;
}

h2 {
	@apply text-2xl;
}

h3 {
	@apply text-xl;
}

.bg-wave {
	background-image: url(../images/bg-wave.svg);
}

.main-container {
    width: 95%;
    max-width: 1600px;
	
	&.w-full {
		max-width:none;
	}
}
.table-blue {
	@apply w-full rounded-xl border border-main overflow-hidden;

	table {
		@apply w-full;

		thead {
			@apply bg-main text-white font-bold text-left px-9;
		}

		tbody {
			@apply text-dark-grey font-bold;
		}

		th,
		td {
			@apply py-4 px-9;

			&:nth-child(1) {
				@apply w-1/3;
			}
		}
	}
}

.table-grey {
	table {
		@apply w-full;

		thead {
			@apply bg-[#ededed] relative;
		}

		tbody {
			tr:nth-child(even) {
				background-color: #fafafa;
			}
		}

		th,
		td {
			@apply px-2 text-left border;
			border-color: #ededed;
		}

		th {
			@apply font-bold text-sm py-2;
			&:not(:last-child) {
				border-right-color: #fff;
			}
		}

		td {
			@apply py-1.5 px-2 text-sm;
		}
	}
}

.btn {
	@apply inline-flex items-center bg-main text-white rounded-md cursor-pointer text-center py-2 px-5 transition-all ease-linear hover:opacity-80;
	
	&-xs {
		@apply py-1 px-3 ;
	}
	&-sm {
		@apply py-1.5 px-4 ;
	}
	&-lg {
		@apply py-2 px-12 text-base;
	}
	&-xl {
		@apply py-3 px-12 text-base;
	}

	&-rounded {
		@apply rounded-full;
	}

	&-ghost {
		@apply bg-transparent text-black border border-black;
	}

	&-white {
		@apply bg-white text-main border border-main;
	}

	&-grey {
		@apply bg-light-grey text-white border border-light-grey;
	}
	
}

.btn-pdr-ref {
	.pop-menu {
		@apply pointer-events-none -mt-3 opacity-0 transition-all ease-in-out;
	}

	&:hover {
		svg {
			.line {
				@apply fill-secondary;
			}
		}
		.pop-menu {
			@apply pointer-events-auto mt-0 opacity-100;
		}
	}
}

a[name="PIP Form"]:hover {

}

.multi-items .field-container:nth-child(n+2) .font-medium {
    display: none;
}
.multi-items .field-container {
    margin-top: 7px;
}
input {
	&.text-disabled{
		background: #EDEDED;
		color: #222222;
	}
}
ul.header-dropdown li a {
    padding: 14px 60px 14px 14px;
	display: table;
}

ul.header-dropdown {
    position: absolute;
    background: #fff;
    top: 70px;
    box-shadow: 1px 1px 10px #0000001a;
}
ul.header-dropdown li:hover {
    background-color: #F5F5F5;
}

.header-dropdown {
	display: none;
}
.header-menu-item:hover .header-dropdown{
	display: block;
}
.header-menu-item:hover {
    --tw-text-opacity: 1;
    color: rgb(0 116 188 / var(--tw-text-opacity));
    font-weight: 700;
	--tw-border-opacity: 1;
    border-color: rgb(0 116 188 / var(--tw-border-opacity));
	border-bottom-width: 4px;

}
img.tooltip-icon {
    display: inline;
    margin-top: -4px;
}
.text-2xl {
    font-size: 1.4rem;
}
.feedback-preview-box {
    background: #fff;
    padding: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
}
.main-container.preview-email-container {
    width: 1000px;
	max-width:100%;
}
.preview-email {
	color:#023974;
	font-weight: bold;
	text-decoration: underline;
    margin-top: -5px;
    display: table;
}
.two-title-header .collapse-title {
    display: table;
}
.two-title-header .collapse-title .flex.items-center {
    width: 100%;
    display: table;
}
.two-title-header .cursor-pointer {
    position: absolute;
    top: 10px;
    right: 0;
}
.rating-button {
    width: 50px;
    height: 50px;
    background: #EEEEEE;
    color: #777777;
    text-align: center;
    border-radius: 100px;
    font-size: 18px;
    line-height: 2.8em;
    float: left;
    margin-right: 15px;
    font-family: auto;
    font-weight: 400;
    cursor: pointer;
}
.rating-button:hover {
    background: #dadada;
}
img.rating-tooltip {
    margin-left: 10px;
}
.rating-button.active {
	background-color: #003974;
	color: #fff;
	font-weight: 900;
} 
.rating-button-group {
    clear: right;
    display: table;
    margin-bottom: 20px;
}
.rating-des b {
    display: table;
    margin-bottom: 5px;
}
.rating-des {
    font-size: 14px;
    color: #444;
}
.dev-cat {
    font-size: 15px;
    margin-top: 2px;
    color: #888;
    margin-bottom: 8px;
}

input.normal-checkbox {
    border: 1px solid #eee;
    opacity: 1;
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
    margin-top: 3px;
}

.checkbox-div {
    margin-top: 8px;
}
.no-label-textarea {
	margin-top: -16px;
	max-height: 120px;
}
.btn-white.grey {
    border-color: #888;
    color: #888;
}